import React from "react"
import SEO from "../components/SEO"
import ResourcesInfo from "../components/ResourcesInfo"

const Resources = () => {
  return (
    <>
      <SEO title="Resources" description="Resources we provide" />
      <ResourcesInfo />
    </>
  )
}

export default Resources
