import React from "react"
import styles from "../../styles/modules/static-pages.module.scss"

const resourcesList = [
  {
    name: "211",
    info: (
      <>
        211 is the most comprehensive source of information about local
        resources and services in the country. You can dial 211 from almost any
        place in the country and confidentially speak to a trained specialist
        who can help connect you to local resources in your community. Call 24/7
        or access information online at
        <a href="https://www.211.org/" target="_blank">
          {" "}
          211.org
        </a>
        .
      </>
    ),
  },
  {
    name: "Utility Assistance",
    info: (
      <>
        Did you know that most utility companies in the country have assistance
        and payment plans for those experiencing financial hardship? Some
        companies even offer housing or water assistance. Reach out today to
        find out what your options are, especially if you’re facing a utility
        shut-off.
      </>
    ),
  },
  {
    name: "Healthcare Assistance",
    info: (
      <>
        211 also offers advice for those{" "}
        <a
          href="https://www.211.org/get-help/healthcare-expenses"
          target="_blank"
        >
          navigating healthcare and medical treatment.
        </a>
      </>
    ),
  },
  {
    name: "Lifelines",
    info: (
      <>
        Dial 988 or 1-800-273-8255 for the{" "}
        <a
          href="https://988lifeline.org/current-events/the-lifeline-and-988/"
          target="_blank"
        >
          988 Suicide and Crisis Lifeline.
        </a>{" "}
        988 has been designated as the new three-digit dialing code that will
        route callers to the National Suicide Prevention Lifeline (now known as
        the 988 Suicide & Crisis Lifeline). <br></br>
        <br></br>
        <b>Veterans Crisis Line:</b> call 1-800-273-TALK (8255) and press 1; or
        text 838255 <br></br>
        <b>Crisis Text Line:</b> text the word 'Home' to 741-741 <br></br>
        <b>The Trevor Lifeline for LGBTQ Youth:</b> call 1-866-488-7386<br></br>
        <b>The Trans Lifeline:</b> call 1-877-565-8860
      </>
    ),
  },
]
const ResourcesInfo = () => (
  <section className={`wrapper wrapper--left`}>
    <h2 className={`x-large-body-copy ${styles.title}`}>Resources</h2>
    <div>
      <p className={`body-copy ${styles.mediumParagraph}`}>
        NeighborShare’s model is to source and validate needs through vetted
        nonprofit partners on the ground in local communities across the
        country. Regrettably, this means we cannot take individual requests at
        this time. If you are experiencing hardship, here are a few resources we
        can share.
      </p>
    </div>
    <>
      {resourcesList.length && (
        <ul className="no-list">
          {resourcesList.map((resource, index) => (
            <li key={index.toString()}>
              <h3 className="header">{resource.name}</h3>
              <p className={`body-copy ${styles.mediumParagraph}`}>
                {resource.info}
              </p>
            </li>
          ))}
        </ul>
      )}
    </>
  </section>
)

export default ResourcesInfo
